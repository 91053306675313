import React from "react"
import * as Icon from "react-feather"
import {
  Monitor,
  Speaker,
  Billing,
  Settings,
  EBook,
  EBookUser,
  LogOut,
  Finances,
  Invoices,
  Archive,
  Card,
  Clients,
  DashboardPpc,
  FinancialReport,
  Categories,
  Widgets,
  Logs,
  LogsEmail,
  LogsAudit,
  LogsLiveClicks,
  LogsApi,
  MonitorUser, SettingsUser
} from "../components/icons/imhIcons";

function perms(role, allowedRoles) {
  const cmode = localStorage.getItem('cmode', null);

  if(cmode === null) {
    return allowedRoles.includes(role);
  }

  return allowedRoles.includes("clientManager");
}

const size = 30;

const navigationConfig = [
    {
        id: 'reports',
        title: 'Reports',
        type:"collapse",
        icon: <Monitor size={size}/>,
        permissions: (role) => {
            return perms(role, ["superAdmin", "adminAccountant", "adminManager"]);
        },
        children: [
            {
                id: "dashboard",
                title: "PPC Dashboard",
                type: "item",
                icon: <DashboardPpc size={size}/>,
                navLink: "/admin/dashboard"
            },
            {
                id: "financial_report",
                title: "Financial Reports",
                type: "item",
                icon: <FinancialReport size={size}/>,
                navLink: "/admin/financial_report"
            }
        ]
    },
    {
        id: "categories",
        title: "Categories",
        type: "item",
        icon: <Categories size={size}/>,
        permissions: (role) => {
            return perms(role, ["superAdmin", "adminManager"]);
        },
        navLink: "/admin/categories",
    },
    {
        id: "clientsAndAds",
        title: "Clients",
        type: "item",
        icon: <Clients size={size}/>,
        permissions: (role) => {
            return perms(role, ["superAdmin", "adminManager"]);
        },
        navLink: "/admin/clients-and-ads",
    },
    {
        id: 'invoices',
        title: 'Finances',
        type:"collapse",
        icon: <Finances size={size}/>,
        permissions: (role) => {
          return perms(role, ["superAdmin"]);
        },
        children: [
            {
                id: "invoices_list",
                title: "Invoices",
                type: "item",
                icon: <Invoices size={size}/>,
                navLink: "/admin/invoices"
            },
            {
                id: "invoices_archive",
                title: "Invoices Archive",
                type: "item",
                icon: <Archive size={size}/>,
                navLink: "/admin/invoices_archive"
            },
            {
                id: "payments",
                title: "Card Payments",
                type: "item",
                icon: <Card size={size}/>,
                navLink: "/admin/payments"
            }
        ]
    },
    {
        id: "ebooks",
        title: "eBooks",
        type: "item",
        icon: <EBook size={size}/>,
        permissions: (role) => {
          return perms(role, ["superAdmin"]);
        },
        navLink: "/admin/ebooks"
    },
    {
        id: "widgets",
        title: "Widgets",
        type: "item",
        icon: <Widgets size={size}/>,
        permissions: (role) => {
          return perms(role, ["superAdmin", "adminManager"]);
        },
        navLink: "/admin/widgets",
    },
    {
        id: 'logs',
        title: 'Logs',
        type:"collapse",
        icon: <Logs size={size}/>,
        permissions: (role) => {
          return perms(role, ["superAdmin"]);
        },
        children: [
            {
                id: "emailLogs",
                title: "Email Logs",
                type: "item",
                icon: <LogsEmail size={size}/>,
                navLink: "/admin/email-logs"
            },
            {
                id: "audit",
                title: "Audit Logs",
                type: "item",
                icon: <LogsAudit size={size}/>,
                navLink: "/admin/audit"
            },
            {
                id: "live_clicks",
                title: "Live Clicks",
                type: "item",
                icon: <LogsLiveClicks size={size}/>,
                navLink: "/admin/live_clicks"
            },
            {
                id: "api_logs",
                title: "Api Logs",
                type: "item",
                icon: <LogsApi size={size}/>,
                navLink: "/admin/api_logs"
            }
        ]
    },
    {
        id: "settings",
        title: "Settings",
        type: "item",
        icon: <Settings size={size}/>,
        permissions: (role) => {
            return perms(role, ["superAdmin"]);
        },
        navLink: "/admin/settings"
    },
    {
        id: "reporting-dashboard",
        title: "Reporting Dashboard",
        type: "item",
        icon: <MonitorUser size={40}/>,
        permissions: (role) => {
          return perms(role, ["clientManager"]);
        },
        navLink: "/dashboard"
    },
    {
        id: "bidding",
        title: "Bidding",
        type: "item",
        icon: <Speaker size={40}/>,
        permissions: (role) => {
          return perms(role, ["clientManager"]);
        },
        navLink: "/bidding",
    },
    {
        id: "agency",
        title: "Agency Dashboard",
        type: "external-link",
        newTab: true,
        icon: <Speaker size={40}/>,
        permissions: (role) => {
          return perms(role, ["clientManager"]);
        },
        navLink: `#`,
    },
    {
        id: "billing",
        title: "Billing",
        type: "item",
        icon: <Billing size={40}/>,
        permissions: (role) => {
          return perms(role, ["clientManager"]);
        },
        navLink: "/billing",
    },
    {
        id: "ebooks",
        title: "eBook MQL Program",
        type: "item",
        icon: <EBookUser size={40}/>,
        permissions: (role) => {
          return perms(role, ["clientManager"]);
        },
        navLink: "/ebooks"
    },
    {
        id: "settings",
        title: "Settings",
        type: "item",
        icon: <SettingsUser size={40}/>,
        permissions: (role) => {
          return perms(role, ["clientManager"]);
        },
        navLink: "/settings"
    },
    {
        id: "logout",
        title: "Log Out",
        type: "item",
        icon: <LogOut size={40}/>,
        permissions: (role) => {
          return perms(role, ["clientManager"]);
        },
        navLink: "/log_out"
    },
];

export default navigationConfig
